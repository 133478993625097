<template>
  <div>
     <div class="table-head">
        <div class="table-btn">
          <el-button type="primary" @click="addTagAction">新建打标签规则</el-button>
        </div>
     </div>
     <div class="line-height"></div>
      <div class="table-box">
          <el-table :data="tableData" style="width: 100%" border ref="tableRef">
            <el-table-column label="任务名称" prop="TaskName" ></el-table-column>
            <el-table-column label="时间范围" >
                <template #default="scope">
                    <div class="cell">
                        <div>{{scope.row.StartTime}}</div>
                        <div>至</div>
                        <div>{{scope.row.EndTime}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="Status" ></el-table-column>
            <el-table-column label="操作" prop="" >
                <template #default="scope">
                    <el-button type="primary" plain @click="editAction(scope.row)">编辑</el-button>
                    <el-button type="primary" plain @click="deletAction(scope.row)" v-if="scope.row.Status=='未开始'">删除</el-button>
                    <el-button type="primary" plain @click="downloadAction(scope.row)">导出明细</el-button>
                </template>
            </el-table-column>
          </el-table>
      </div>
       <TableFoot :pagination="pageObj"  @changePage="changePage" @changeSize="changeSize" />
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox, ElMessage } from "element-plus";
import {GroupTagGetTaskList,GroupTagGetTaskLog,GroupTagDeleteTask}from "@/helper/tag"
import  tools  from '@/assets/js/tools'
export default {
  components: {TableFoot},
  setup() {
    const router = useRouter();
    const tableData = ref([])

    onMounted(()=>{
        searchAction()
    })

    function searchAction(){
        let params={
            PageNum:pageObj.PageIndex,
            PageSize:pageObj.PageSize
        }
        GroupTagGetTaskList(params).then(res=>{
            pageObj.TotalRow = res.TotalCount
            tableData.value = res.List || []
            for(let item of tableData.value){
                item.StartTime = tools.GMTToStr(item.StartTime,2) 
                item.EndTime = tools.GMTToStr(item.EndTime,2)
                item.Status = item.Status == 0 ? '未开始' : item.Status == 1 ? '计算中' : item.Status == 2 ? '已完成' : ''
            } 
        })
    }
    
    /******************点击事件*********************/
    //新建
    function addTagAction(){
        router.push({name: "AddTagRule"})
    }


    //编辑
    function editAction(item){
        item.Status = item.Status == '未完成' ? 0 : item.Status == '计算中' ? 1 : item.Status == '已完成' ? 2 : ''
        router.push({name: "AddTagRule",query:{SID:item.TaskID,Status:item.Status}})
    }

    //删除
    function deletAction(item){
        ElMessageBox.confirm("是否删除此任务？", "确认提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
            GroupTagDeleteTask({TaskID:item.TaskID}).then((res) => {
                ElMessage({
                    type: "success",
                    message: "删除成功",
                });
              searchAction()
            });
        }).catch(() => {});
        
    }

    //导出
    function downloadAction(item){
        GroupTagGetTaskLog({TaskID:item.TaskID}).then(res=>{
           window.location.href=res.File
           ElMessage({
            type: 'success',
            message: '导出成功!',
          });
        })
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
        TotalRow: 0,
        PageIndex: 1,
        PageSize: 10,
    })
    //页码改变
    function changePage(index) {
        pageObj.PageIndex = index;
        searchAction();
    };
    function changeSize(size) {
        pageObj.PageSize = size;
        pageObj.PageIndex = 1;
        searchAction();
    }

    return{
        tableData,
        addTagAction,
        pageObj,
        changePage,
        changeSize,
        deletAction,
        editAction,
        searchAction,
        downloadAction
    }
  }
}

</script>

<style lang="scss" scoped>
.table-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    padding: 5px 10px;
    flex-wrap: wrap;
}

.cell{
    display: flex;
    align-items: center;
}
</style>